import React from "react";
import Home from "../../Components/Home/index";

type Props = {};

const HomePage = (props: Props) => {
  return (
    <>
      <Home />
    </>
  );
};

export default HomePage;