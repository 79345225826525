import React from "react";

const Home = () => {
    return (
        <div className="bg-gray-100 min-h-screen flex items-center justify-center">
            <div className="text-center bg-white p-8 rounded-lg shadow-lg">
                <h1 className="text-3xl font-bold text-gray-800">Welcome!</h1>
                <p className="mt-4 text-gray-600">
                    This site is a React app hosted on my personal server. 🚀
                </p>
                <p className="mt-2 text-gray-600">
                    This site is a work in progress, but thanks for checking in! 😊
                </p>
            </div>
        </div>
    );
};

export default Home;
